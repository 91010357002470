
import {defineComponent, onMounted, reactive, toRefs, computed, ref, UnwrapRef, toRaw} from "vue";
import router from "@/router";
import StorageUtil from "@/utils/storage_util";
import PageUtil from "@/utils/page_util";
import {IFeedbackItem} from "@/models/logistics_model";
import {dateFormat} from "@/utils/common";
import {IAppVersionItem, IUpdateAppVersionItem} from "@/models/version_model";
import ApiSetting from "@/request/apis/api_setting";
import UploadComponent from "@/components/UploadComponent.vue"
import {message} from "ant-design-vue";
interface IState {
  list: IAppVersionItem[],
  total: number;
  loading: boolean;
  searches: any
  visible: boolean
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '系统平台',
    dataIndex: 'platform_code',
    key: 'platform_code',
  },
  {
    title: '版本名称',
    dataIndex: 'version_name',
    key: 'version_name',
  },
  {
    title: '版本号',
    dataIndex: 'version_code',
    key: 'version_code',
  },
  {
    title: '下载地址',
    dataIndex: 'package_url',
    key: 'package_url',
  },
  {
    title: '更新内容',
    dataIndex: 'instruction',
    key: 'instruction',
  },
  {
    title: '更新时间',
    dataIndex: 'create_time',
    key: 'create_time',
  },
  {
    title: '是否更新',
    dataIndex: 'updatable',
    key: 'updatable',
    align: 'center'
  },
  {
    title: '操作',
    key: 'control',
  },
];

export default defineComponent({
  name: 'AppVersionList',
  components: {UploadComponent},
  setup() {
    const fileUploadRef = ref()
    const formRef = ref()
    const rules = {
      platform_code: [{required: true, message: "请选择系统"},],
      version_code: [{required: true, message: "请输入版本号"},],
      version_name: [{required: true, message: "请输入版本名称"},],
      package_url: [{required: true, message: "请上传或输入下载地址"},],
      instruction: [{required: true, message: "请输入更新内容"},],
    }

    let formState: UnwrapRef<IUpdateAppVersionItem> = reactive({
      "platform_code": 1,
      "version_code": "",
      "version_name": "",
      "package_url": "",
      "instruction": "",
      updatable: 1,//是否更新，默认是
      id:""
    })

    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      visible: false,
      searches: {
        page: PageUtil.pageNo,
        limit: PageUtil.pageSize,
      },
    })

    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      StorageUtil.setParams(params)
      state.loading = true
      const res = await ApiSetting.getAppVersionList(params);
      state.loading = false
      if (res && res.error_code === 0) {
        const data: any = res.data
        state.total = data.count
        state.list = data.list
      }
    }

    // 设置modal显示隐藏
    const setVisible = (flg: boolean, item: IAppVersionItem | undefined = undefined) => {
      state.visible = flg
      if(flg && item){
        formState.platform_code = item ? Number(item!.platform_code) : 1
        formState.version_code = item ? item!.version_code : ''
        formState.version_name = item ? item!.version_name : ''
        formState.package_url = item ? item!.package_url : ''
        formState.instruction = item ? item!.instruction : ''
        formState.updatable = item ? item!.updatable : 1
        formState.id = item ? item.id : ''
      }
      if (!flg) {
        setTimeout(() => {
          formRef.value.resetFields()
          fileUploadRef.value.reset()
        }, 300)
      }
    }

    // 文件上传
    const onFileChange = (url: string) => formState.package_url = url

    // 提交
    const onSubmit = () => {
      formRef.value
          .validate()
          .then(async () => {
            let params = {...toRaw(formState)}
            state.loading = true
            const res = await ApiSetting.updateAppVersion(params);
            state.loading = false
            if (res && res.error_code == 0) {
              message.success(params.id ? '编辑成功' :'新增版本成功')
              state.searches.page = 1
              setVisible(false)
              await getList();
            } else {
              message.error(res?.msg || '操作失败，请重试');
            }
          })
          .catch((error: any) => {
            console.log("error", error)
          })
    }

    const delAppVersion = async (item: IAppVersionItem) => {
      const res = await ApiSetting.delAppVersion(item.id);
      if (res && res.error_code == 0) {
        message.success('删除版本成功')
        await getList();
      } else {
        message.error(res?.msg || '删除版本失败，请重试');
      }
    }


    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      state.searches[key] = value
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    // 重置search参数
    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    // 初始化search参数
    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      pagination,
      handleTableChange,
      onSearch,
      resetSearch,
      onSubmit,
      setVisible,
      dateFormat,
      delAppVersion,
      onFileChange,
      fileUploadRef,
      rules,
      formRef,
      formState,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
    }
  }
})
